/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import { mapSections } from '@leshen/gatsby-theme-contentful/utils/determiners'

const DefaultCart = ({ data }) => {
  const {
    contentfulPage: { sections, spanish },
  } = data

  const sectionOptions = {
    spanish,
  }

  return (
    <Layout
      data={data}
      main={
        <Main>
          <div id="loadingScrollTarget" />

          {/* Pass the CTA Bar so it behaves like normal when coming from Contentful */}
          {/* {!hideStickyCTA && <CTABarWithMapi showCart />} */}

          {/* Render the rest of the sections from Contentful like normal */}
          {sections &&
            sections.map((section) => mapSections(section, sectionOptions))}
        </Main>
      }
    />
  )
}

export default DefaultCart

export const query = graphql`
  query DefaultCartQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
